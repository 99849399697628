import React from "react";
import { ThemeProvider } from "../../Utils/themeProvider";
import { App } from "../Components/App";
import "../stylesheet.css";
import "../font.css";
import "../index.css";

export default function IndexPage() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
}
