import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { Logo } from "../../../verteego/src/images/Logo";

const useStyles = makeStyles(() => ({
  main: {
    width: "100%",
    height: "100vh",
  },
  boxStyle: {
    height: "18%",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #242422",
    backgroundColor: "#fff",
  },
  separator: {
    width: 80,
    height: 6,
    display: "inline-block",
    marginBottom: 24,
    background: "#242422",
  },
  separatorEnterainment: {
    background: "#F2E61A",
  },
  separatorAdv: {
    background: " #00C8C8",
  },
  separatorRent: {
    background: "#E7343F",
  },
  link: {
    color: "#000",
    fontFamily: "Agrandir-Heavy",
    letterSpacing: -1.5,
    fontSize: 32,
    margin: 0,
    textTransform: "uppercase",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  comingSoon:{
    fontSize:14,
    textTransform:"uppercase"
  },
  boxHome: {
    height: "28%",
    background: "#242422",
    "& p": {
      color: "#FFF",
      fontFamily: "Agrandir-Heavy",
      fontSize: 16,
      lineHeight: "20,8px",
      letterSpacing: -1.5,
      textTransform: "uppercase",
      textDecoration: "none",
    },
  },
  
}));

const MobileHome = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <a className={clsx(classes.boxStyle, classes.boxHome)}>
        <div className={clsx(classes.separator)} />
        <Logo fill="#FFF" />
        <p>Play Video</p>
      </a>
      <a href="https://entertainment.verteego.it" className={classes.boxStyle}>
        <div
          className={clsx(classes.separator, classes.separatorEnterainment)}
        />
        <h2 className={classes.link}>Entertainement</h2>
      </a>
      <a href="https://ad.verteego.it" className={classes.boxStyle}>
        <div className={clsx(classes.separator, classes.separatorAdv)} />
        <h2 className={classes.link}>Advertising</h2>
      </a>
      <a className={classes.boxStyle}>
        <div className={clsx(classes.separator)} />
        <h2 className={classes.link}>Little Bay</h2>
        <p className={classes.comingSoon}>Coming soon</p>
      </a>
      <a className={classes.boxStyle}>
        <div className={clsx(classes.separator, classes.separatorRent)} />
        <h2 className={classes.link}>Rent</h2>
        <p className={classes.comingSoon}>Coming soon</p>
      </a>
    </div>
  );
};

export default MobileHome;
